import { useQuery } from '@apollo/client'
import { ROUGEX_ADDRESSES } from 'constants/addresses'
import { ChainId } from 'constants/chains'
import { BigNumber } from 'ethers'
import { PollingInterval, usePollQueryWhileMounted } from 'graphql/data/util'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { useHttpPoolListData, useVoteCurrentId } from 'state/http/hooks'
import { useRoguxCurrency } from 'constants/tokens'
export interface IPoolSwapUser {
  userAddress: string
  swapVolume: string
  perpVolume: string
  totalValume: string
}

export interface IPoolVolume {
  poolAddress: string
  swapVolume: string
  perpVolume: string
  totalValume: string
  swapedUser: IPoolSwapUser[]
}

interface ITokenInfo {
  id: string
  symbol: string
  decimals: string
  derivedETH?: string
  name: string
  valueUSD?: string
  shit?: boolean
}

export interface IReward {
  tokenInfo: ITokenInfo
  rewardAmount: string
  rewardAmountUSD?: string
}

interface ISwapMinning {
  swapMinningAddress: string
  feeReward: IReward[]
  bribeReward: IReward[]
}

interface IVotingReward {
  VotingRewardAddress: string
  feeReward: IReward[]
  bribeReward: IReward[]
}

interface Ihypervisor {
  depositedToken0Amount: string
  depositedToken1Amount: string
  hypervisorAddress: string
  depositedUSDAmount: string
  share: string
}
interface IMasterChef {
  masterChefAddress: string
}

interface IweeklyVotedTotal {
  totalVoted: string
}
interface GaugeInfo {
  lpAddr: string
  maxLevleage: string
  poolFee: string
  stakedAmountToken0: string
  stakedAmountToken1: string
  stakedAmountUSD: string
  token0: ITokenInfo
  token1: ITokenInfo
  feeReward: IReward[]
  bribeReward: IReward[]
  swapMinning: ISwapMinning
  votingReward: IVotingReward
  PoolVolume: IPoolVolume[]
  hypervisor: Ihypervisor
  masterChef: IMasterChef
  weeklyVotedTotal: IweeklyVotedTotal[]
}

interface Bundles {
  ethPriceUSD: string
  voteTotalBribe: [{ totalBribeUSDAmount: string }]
  totalDepositedUSDAmount: string
}
interface FactorieInfos {
  totalFeesUSD: string
}

export interface IOrder {
  id: string
  spotpool: string
  account: string
  perpPool: string
  token0: ITokenInfo
  token1: ITokenInfo
  key: string
  collateralIn?: string
  executionFee: string
  sizeDelta: string
  long0: boolean
  triggerAboveThreshold: boolean
  shouldWarp: boolean
  triggerPrice: string
  executed: boolean
  cancelled: boolean
  collateralDelta?: string
  __typename: string
  twapPrice: BigNumber
}

export interface QueryPoolDataInfo {
  bundles: Bundles[]
  gauges: GaugeInfo[]
  tokens: ITokenInfo[]
  factories: FactorieInfos[]
  token: ITokenInfo
  totalVoteds?: any
  prepFeeTotals?: any
  spotFeeTotals?: any
  increaseOrders: IOrder[]
  decreaseOrders: IOrder[]
  activePositions: any[]
}

const roxAddr ='0xdf632bfe6feec28eecfd5bd981a2f1e8f4a989c8' // chainid
const query = gql`
  query AllData($weeklyId: String!) {
    bundles {
      ethPriceUSD
      totalDepositedUSDAmount
    }
    activePositions(where:{active:true}){
      entry0Price
      entry1Price
      hasProfit
      profitDelta
      v3Pool{
      token0{
        id
        symbol
        decimals
        name
        derivedETH
      }
      token1{
        id
        symbol
        name
        decimals
        derivedETH
      }
    }
    prepPool{
      adlLong0
      adlLong1
      tPidLong0
      tPidLong1
    }
    entryPos
    key
    spotPoolAddress
    prepPoolAddress
    size
    long0
    collateral
    reserve
    close0Price
    close1Price
    liqResv
  }
  increaseOrders (where:{executed:false,cancelled:false}){
    id
    spotpool
    account
    perpPool
    token0{
      id
      symbol
      decimal
      name
    }
    token1{
      id
      symbol
      decimal
      name
    }
    key
    collateralIn
    executionFee
    sizeDelta
    long0
    triggerAboveThreshold
    shouldWarp
    triggerPrice
    executed
    cancelled
  }
  decreaseOrders(where:{executed:false,cancelled:false}){
    id
    spotpool
    account
    perpPool
   token0{
      id
      symbol
      decimal
      name
    }
    token1{
      id
      symbol
      decimal
      name
    }
    key
    sizeDelta
    collateralDelta
    executionFee
    long0
    triggerAboveThreshold
    shouldWarp
    triggerPrice
    executed
    cancelled
  }

    totalVoteds(where: { weeklyId: $weeklyId }) {
      totalVoted
    }
    prepFeeTotals(where:{ weeklyId: $weeklyId }){
      feeUSD
    }
    spotFeeTotals(where:{ weeklyId: $weeklyId }){
      feeUSD
    }

    factories {
      totalFeesUSD
    }
    token(id: "${roxAddr}") {
      derivedETH
    }
    tokens {
      name
      id
      symbol
      decimals
      derivedETH
      valueUSD
      shit
    }
    gauges {
      weeklyVotedTotal(where: { weeklyId: $weeklyId }) {
        totalVoted
      }
      maxLevleage
      lpAddr
      poolFee
      hypervisor {
        hypervisorAddress
        share
        depositedToken0Amount
        depositedToken1Amount
        depositedUSDAmount
      }
      masterChef {
        masterChefAddress
      }
      PoolVolume(where: { weeklyId: $weeklyId }, orderBy: swapVolume, orderDirection: desc) {
        weeklyId
        poolAddress
        swapVolume
        perpVolume
        totalValume
        swapedUser(where: { weeklyId: $weeklyId }, orderBy: swapVolume, orderDirection: desc) {
          userAddress
          swapVolume
          perpVolume
          totalValume
        }
      }
      token0 {
        id
        symbol
        decimals
        name
        derivedETH
        valueUSD
        shit
      }
      token1 {
        id
        symbol
        name
        decimals
        derivedETH
        valueUSD
        shit
      }
      swapMinning {
        swapMinningAddress
        feeReward(where: { weeklyId: $weeklyId }) {
          tokenInfo {
            id
            symbol
            decimals
            derivedETH
            valueUSD
            shit
          }
          rewardAmount
        }
        bribeReward(where: { weeklyId: $weeklyId }) {
          tokenInfo {
            id
            symbol
            decimals
            derivedETH
            valueUSD
            shit
          }
          rewardAmount
        }
      }
      votingReward {
        VotingRewardAddress
        feeReward(where: { weeklyId: $weeklyId }) {
          tokenInfo {
            id
            symbol
            decimals
            derivedETH
            valueUSD
            shit
          }
          rewardAmount
        }
        bribeReward(where: { weeklyId: $weeklyId }) {
          rewardAmountUSD
          tokenInfo {
            id
            symbol
            decimals
            derivedETH
            valueUSD
            shit
          }
          rewardAmount
        }
      }
      feeReward(where: { weeklyId: $weeklyId }) {
        tokenInfo {
          id
          symbol
          decimals
          derivedETH
          valueUSD
          shit
        }
        rewardAmount
      }
      bribeReward(where: { weeklyId: $weeklyId }) {
        tokenInfo {
          id
          symbol
          decimals
          derivedETH
          valueUSD
          shit
        }
        rewardAmount
      }
    }
  }
`
export const useQueryAllData = () => {
  const currentId = useVoteCurrentId()

  const {
    data,
    loading: isLoading,
    error,
  } = usePollQueryWhileMounted(
    useQuery(query, {
      variables: {
        weeklyId: currentId.toFixed(),
      },
    }),
    PollingInterval.Lowspeed
  )

  return useMemo(
    () => ({
      error,
      isLoading,
      data,
    }),
    [data, error, isLoading]
  )
}

export const useTotalDepositedUSDAmount = () => {
  const data = useHttpPoolListData()
  return useMemo(() => {
    const totalDepositedUSDAmount = data?.bundles[0]?.totalDepositedUSDAmount
    return totalDepositedUSDAmount
  }, [data])
}

export const useTotalFeesUSD = () => {
  const data = useHttpPoolListData()
  return useMemo(() => {
    const res = Number(data?.prepFeeTotals[0]?.feeUSD || 0) + Number(data?.spotFeeTotals[0]?.feeUSD || 0)
    return res
  }, [data])
}

export const useEthPriceUSD = () => {
  const data = useHttpPoolListData()
  return useMemo(() => {
    const res = data?.bundles[0]?.ethPriceUSD
    return res
  }, [data])
}

export const useGraphTokenList = () => {
  const data = useHttpPoolListData()
  return useMemo(() => {
    const res = data?.tokens
    return res
  }, [data])
}

export const useGraphTokenListMAP = () => {
  const data = useHttpPoolListData()

  return useMemo(() => {
    return data?.tokens.reduce<{ [key: string]: ITokenInfo }>((pre: any, curr) => {
      pre[curr.id.toLocaleLowerCase()] = curr
      return pre
    }, {})
  }, [data])
}

export const useRoxPriceETH = () => {
  const data = useHttpPoolListData()
  return useMemo(() => {
    const res = data?.token?.derivedETH
    return res
  }, [data])
}

export const useOrderBookList = () => {
  const data = useHttpPoolListData()
  return useMemo(() => {
    if (!data) return
    const res = [...data.increaseOrders, ...data.decreaseOrders]
    return res
  }, [data])
}

export const useOrderLiquidationList = () => {
  const data = useHttpPoolListData()
  return useMemo(() => {
    if (!data) return
    const res = data.activePositions
    return res
  }, [data])
}
