import { Interface } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import NFTPositionManagerABI from 'abis/NftPositionManager.json'
import { useActiveChainId } from 'connection/useActiveChainId'
import { CallStateResult, useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useMemo } from 'react'
import { PositionDetails } from 'types/position'
import { useContractReads } from 'wagmi'

import { useV3NFTPositionManagerContract } from './useContract'

const NFTPositionManagerABIs = new Interface(NFTPositionManagerABI.abi)
interface UseV3PositionsResults {
  loading: boolean
  positions?: PositionDetails[]
}

function useV3PositionsFromTokenIds(tokenIds: BigNumber[] | undefined): UseV3PositionsResults {
  const positionManager = useV3NFTPositionManagerContract()

  const contracts: any = useMemo(() => {
    if (!tokenIds) return []

    return tokenIds.map((tokenID) => {
      return {
        address: positionManager?.address,
        abi: NFTPositionManagerABI.abi,
        functionName: 'positions',
        args: [tokenID.toString()],
      }
    })
  }, [positionManager?.address, tokenIds])

  const {
    data: results,
    isLoading,
    isError,
  } = useContractReads({
    contracts: contracts ? [...contracts] : [],
  })

  // const results = useSingleContractMultipleData(positionManager, 'positions', inputs)

  const loading = isLoading
  const error = isError

  const positions = useMemo(() => {
    if (!loading && !error && tokenIds && results) {
      return results.map(({ result }: any, i) => {
        const tokenId = tokenIds[i]
        return {
          tokenId,
          fee: result.fee,
          feeGrowthInside0LastX128: BigNumber.from(0),
          feeGrowthInside1LastX128: BigNumber.from(0),
          liquidity: BigNumber.from(result.liquidity),
          nonce: result.nonce,
          operator: result.operator,
          tickLower: result.tickLower,
          tickUpper: result.tickUpper,
          token0: result.token0,
          token1: result.token1,
          spotFeeOwed0: result.spotFeeOwed0,
          spotFeeOwed1: result.spotFeeOwed1,
          perpFeeOwed0: result.perpFeeOwed0,
          perpFeeOwed1: result.perpFeeOwed1,
          tokensOwed0: result.tokenOwe0,
          tokensOwed1: result.tokenOwe1,
        }
      })
    }
    return undefined
  }, [loading, error, results, tokenIds])

  return {
    loading,
    positions: positions?.map((position, i) => ({ ...position, tokenId: BigNumber.from(contracts[i].args[0]) })),
  }
}

interface UseV3PositionResults {
  loading: boolean
  position?: PositionDetails
}

export function useV3PositionFromTokenId(tokenId: BigNumber | undefined): UseV3PositionResults {
  const position = useV3PositionsFromTokenIds(tokenId ? [tokenId] : undefined)
  return {
    loading: position.loading,
    position: position.positions?.[0],
  }
}

export function useV3Positions(account: string | null | undefined): UseV3PositionsResults {
  const positionManager = useV3NFTPositionManagerContract()

  const { loading: balanceLoading, result: balanceResult } = useSingleCallResult(positionManager, 'balanceOf', [
    account ?? undefined,
  ])

  // we don't expect any account balance to ever exceed the bounds of max safe int
  const accountBalance: number | undefined = balanceResult?.[0]?.toNumber()

  const tokenIdsArgs = useMemo(() => {
    if (accountBalance && account) {
      const tokenRequests = []
      for (let i = 0; i < accountBalance; i++) {
        tokenRequests.push([account, i])
      }
      return tokenRequests
    }
    return []
  }, [account, accountBalance])

  const tokenIdResults = useSingleContractMultipleData(positionManager, 'tokenOfOwnerByIndex', tokenIdsArgs)
  const someTokenIdsLoading = useMemo(() => tokenIdResults.some(({ loading }) => loading), [tokenIdResults])

  const tokenIds = useMemo(() => {
    if (account) {
      return tokenIdResults
        .map(({ result }) => result)
        .filter((result): result is CallStateResult => !!result)
        .map((result) => BigNumber.from(result[0]))
    }
    return []
  }, [account, tokenIdResults])

  const { positions, loading: positionsLoading } = useV3PositionsFromTokenIds(tokenIds)

  return {
    loading: someTokenIdsLoading || balanceLoading || positionsLoading,
    positions,
  }
}

/**
 * 判断两个对象的key是否相同
 * key指的是token0, token1, fee
 * @param obj1
 * @param obj2
 * @returns
 */
export function isKeyEqual(obj1: any, obj2: any): boolean {
  if (!obj1 || !obj2) return false

  const { token0, token1, fee } = obj1
  const { token0: token00, token1: token11, fee: fee1 } = obj2
  if (!token0 || !token1 || !fee || !token00 || !token11 || !fee1) return false

  // TODO 如果按照排序的算法，则容错率会下降，
  // 所有应该采用正向、反向判断key是否相等
  const noCaseK1 = (token0 + token1 + fee).toLowerCase()
  const noCaseK1Reverse = (token1 + token0 + fee).toLowerCase()

  const noCaseK2 = (token00 + token11 + fee1).toLowerCase()
  const noCaseK2Reverse = (token11 + token00 + fee1).toLowerCase()

  // const k1 = [...noCaseK1].sort().join('')
  // const k2 = [...noCaseK2].sort().join('')

  return (
    noCaseK1 === noCaseK2 ||
    noCaseK1 === noCaseK2Reverse ||
    noCaseK1Reverse === noCaseK2 ||
    noCaseK1Reverse === noCaseK2Reverse
  )

  // console.log('[k1-k2]:', k1, k2)
  // return k1 === k2
}

export function isTradeKeyEqual(obj1: any, obj2: any): boolean {
  if (!obj1 || !obj2) return false

  const { token0, token1, fee, long0: long0 } = obj1
  const { token0: token00, token1: token11, fee: fee1, long0: long1 } = obj2
  if (!token0 || !token1 || !fee || !token00 || !token11 || !fee1 || long0 == undefined) return false
  // TODO 如果按照排序的算法，则容错率会下降，
  // 所有应该采用正向、反向判断key是否相等
  const noCaseK1 = (token0 + token1 + fee + long0).toLowerCase()
  const noCaseK1Reverse = (token1 + token0 + fee + long0).toLowerCase()

  const noCaseK2 = (token00 + token11 + fee1 + long1).toLowerCase()
  const noCaseK2Reverse = (token11 + token00 + fee1 + long1).toLowerCase()

  // const k1 = [...noCaseK1].sort().join('')
  // const k2 = [...noCaseK2].sort().join('')

  return (
    noCaseK1 === noCaseK2 ||
    noCaseK1 === noCaseK2Reverse ||
    noCaseK1Reverse === noCaseK2 ||
    noCaseK1Reverse === noCaseK2Reverse
  )

  // console.log('[k1-k2]:', k1, k2)
  // return k1 === k2
}

/**
 * 从所有仓位中过滤出与代表仓位token0, token1, fee相同的仓位
 * @param representPosition 代表仓位，被比较的仓位
 * @returns
 */
export function useV3SamePositionsFromOne(representPosition: any) {
  const { account } = useActiveChainId()
  const { positions, loading } = useV3Positions(account)

  const filteredPositions = useMemo(() => {
    return positions?.filter((positionItem: any) => {
      return isKeyEqual(representPosition, positionItem)
    })
  }, [positions, representPosition])

  return {
    positions: filteredPositions,
    loading,
  }
}
